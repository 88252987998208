@import "resources/normalize.scss";
@import "resources/variables.scss";
@import "resources/mixins.scss";
@import "resources/typography.scss";

@import "components/header.scss";
@import "components/navigation.scss";
@import "components/form.scss";

body {
  background: $bg;
  font-family: 'Alegreya', serif;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.5s, transform 0.6s cubic-bezier(0.0, 0.0, 0.2, 1);
  transform: translateY(20px);

  &.shown {
    opacity: 1;
    transform: translateY(0px);
  }
}

.container {
  max-width: 1400px;
  margin: 0 auto 100vh;
  padding: 0 50px;

  img {
    max-width: 100%;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 80px;
  }

  .image-half {
    flex: 0 0 44%;
  }
  .image-half-smal {
    flex: 0 0 40%;

    img {
      transition-delay: 0.4s;
    }
  }
}

.spacer {
  height: 100vh;
}
