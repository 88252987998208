

.navigation-toggle {
  cursor: pointer;
  path {
    transition: fill .3s;
  }

  &:hover path {
    fill: darken(#78543D, 10%);
  }
}

.navigation {
  position: fixed;
  top: 0;
  right: -350px;
  height: 100%;
  width: 350px;
  padding: 50px;
  background: $bg;
  z-index: 150;
  transition: right 0.2s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s 0.4s;
  box-sizing: border-box;
  opacity: 0;
  box-shadow: 0 0 54px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;

  &--open {
    right: 0;
    opacity: 1;
    transition: right 0.4s 0s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.4s 0s;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 20px;
  }

  li {
    margin-bottom: 20px;
  }
  a {
    text-decoration: none;
    color: #78543d;
    border-bottom: 2px solid transparent;
    transition: border 0.2s;

    &:hover {
      color: darken(#78543d, 10%);
      border-bottom-color: rgba(#78543d, 0.8);
    }
  }

  .parent ul {
    font-size: 16px;
    margin: 10px 0 20px;

    li {
      margin-bottom: 10px;
    }
    a {
      color: rgba(#78543d, 0.8);
    }
  }
}
