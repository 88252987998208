/* alegreya-regular - latin */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 400;
  src: local('Alegreya Regular'), local('Alegreya-Regular'),
       url('../fonts/alegreya-v13-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/alegreya-v13-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* alegreya-500 - latin */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 500;
  src: local('Alegreya Medium'), local('Alegreya-Medium'),
       url('../fonts/alegreya-v13-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/alegreya-v13-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* alegreya-700 - latin */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 700;
  src: local('Alegreya Bold'), local('Alegreya-Bold'),
       url('../fonts/alegreya-v13-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/alegreya-v13-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}