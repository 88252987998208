.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 20px;

  @include sm {
    margin: 150px;
  }

  .header-logo {
    display: block;
    max-width: 100%;
    width: 175px;

    @include sm {
      width: 351px;
    }
  }

  &__nav-button {
    width: 24px;

    @include sm {
      width: 48px;
    }
  }
}

.fixed-header {
  position: fixed;
  left: 0;
  right: 0;
  display: grid;
  align-items: center;
  background: $bg;
  padding: 30px;
  transition: top .3s;
  top: -150px;
  z-index: 200;

  &.is-visible {
    top: 0;
  }

  .header-logo {
    width: 160px;
  }

  &__nav-button {
    position: absolute;
    right: 30px;
    width: 24px;
  }
}