.form-container {
  max-width: 1000px;
  margin: 0 auto 50px;
  display: flex;
  justify-content: center;

  .form {
    max-width: 600px;
    background: #fff;
    padding: 50px;
    box-shadow: 0 10px 54px rgba(0, 0, 0, 0.2);

    &__title {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 20px;
      color: rgba(120, 84, 61, 0.8);
    }

    &__label {
      display: block;
      margin-bottom: 10px;
      font-size: 18px;
      color: rgba(120, 84, 61, 0.8);
    }

    &__label_inline {
      font-size: 18px;
      line-height: 26px;
      color: rgba(120, 84, 61, 0.8);

      a {
        color: inherit;
      }
    }

    &__row {
      margin-bottom: 20px;
    }

    input[type="checkbox"] {
      margin-right: 5px;
      display: inline-block;
    }

    input[type="text"],
    input[type="email"],
    textarea,
    select {
      border: 1px solid #bfbcb9;
      padding: 10px;
      border-radius: 3px;
      width: 100%;
    }

    &__button {
      -webkit-appearance: none;
      border: 0;
      background: #bfbcb9;
      color: #fff;
      padding: 0 15px;
      border-radius: 3px;
      font-size: 16px;
      line-height: 36px;
      cursor: pointer;

      &:hover {
        background: #78543D;
      }
    }
  }
}
